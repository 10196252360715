/* Container for the footer */
.footer {
background-color: black;
  color: #fff;
  padding-top:30px;
  padding-bottom: 0px;
  height: auto;
  overflow-x: hidden !important;
  width: auto;
  font-family: "Roboto", sans-serif;
}

/* Wrapper for the footer content */
.wrapper_footer {
    height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
    padding: 2px;
    margin-top: -30px;
    padding-top: 19px;
    padding-left: 11px;
    overflow-x: hidden;
    width: 100vw;
    background-color: hsla(0,0%,6%,.95);
    padding-bottom: 15px;
}

/* Logo section */
.foot_about {
  width: 307px;
  padding-left: 40px;
  margin-left: 20px;
  font-family: "Roboto", sans-serif;

}

.foot_logo{
     width: 100%;
    margin-left: 10px;
    margin-top: -12px;
}
.Foot_heading{
    font-weight: bolder;
    margin-top: -64px;
    color: #a29d9d;
    font-size: 24px;
    margin-left: 15px;
}
.foot_about img {
     width: 30%;
    padding: 0px;
    margin: 15px 0px 0px 35px;

}

.foot_subs {
    width: 90%;
 margin-left: 5px;
}

.foot_subs h5 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-left: 5px;
  width: 83%;
}
.foot_form{
    width: fit-content;
}
.input_field_footer {
  color: #f5f1f1;
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
   background: #4036368f;
    border: none;
    border-radius: 1px;
    width: 85%;
    height: 18px;
    outline: none;
}

.input_field_footer::placeholder {
  color: #b7afaf;
}

.foot_subs p {
    font-size: 9px;
    margin-bottom: 10px;
    color: #ab9999;
    margin-left: 0px;
}

.foot_subs a {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.btnFooter {

  border: none;
  color: #222;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  text-transform: uppercase;
  width: 92%;
    border-radius: 1px;
    color: white;
    background: rgba(222, 9, 9, 0.744);
}

.btnFooter:hover {
  background-color: rgb(215, 6, 6);
  color: rgb(236, 227, 227);
}

.foot_subs_text:hover{
  color: #f00;
}

/* Menu section */

 /* Grid container for all menu sections */
  .foot_menu_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    grid-gap: 20px;
      width: fit-content;
    margin: 0px -10px;
    padding: 0px 5px;
  }


.foot_menu h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.foot_menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.foot_menu li {
  margin-bottom: 5px;
}

.foot_menu li a {
       color: #fff;
    font-size: 12px;
    text-decoration: none;
    font-weight: 500;
    
}

.foot_menu li a:hover {
  color: #f00;
}

/* Links section */
.foot_links {
  width: fit-content;
    margin-right: 40px;
}

.foot_dowload_appLink {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    margin-top: 43px;
  margin-bottom: 20px;
}
.foot_dowload_appLink h5{
    height: auto;
    margin-top: -32px;
    font-size: 16px;
    font-weight: bolder
}
.foot_dowload_appLink span{
        margin-top: -10px;
}
.foot_dowload_appLink img {
  height: 32px;
  margin-right: 15px;
     margin-top: 19px;
}
.app_links{
  margin-top: -2rem;
}

.foot_social a {
  color: #fff;
  font-size: 14px;
  margin-right: 10px;
}

.foot_social a:hover {
  color: rgb(179, 9, 9);
}

/* separator */
.separatorFooter{
    border-top: .7px solid rgba(48, 47, 47, 0.712);
   
}

.css-i4bv87-MuiSvgIcon-root{
    font-size: 2rem !important;
  }
/* sub_footer */

.sub_footer_root{
 display: flex;
 flex-direction: column;
 align-items: center;
 text-decoration: none;
 gap: 5px;
}

.foot_policyLink ul{
    display: flex;
justify-content: space-between;
text-decoration: none;

}
.foot_policyLink ul li{
    text-decoration: none;
    list-style: none;
}
.foot_policyLink ul li>*{
    color: white;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
}
.foot_copyright>*{
display: flex;
  gap: 5px;
  text-decoration: none;
    font-weight: 400;
    font-size: 12px;
    margin-left: 38px;
 
    align-content: center;
}
.foot_copyright a{
    text-decoration: none;
    color: #001c25;
}
.foot_copyright a:hover{
    color: #f00;
}
 .facebook_icon {
 
  font-size: 18px;
 }

.facebook_icon:hover{
  color: #3b5998 ;
}
.twitter_icon {
 
  font-size: 18px;

}

.twitter_icon:hover{
  color: #1DA1F2 ;
}
.insta_icon {
 
  font-size: 18px;

}
.insta_icon:hover{
 color: #c13584;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
}

.likedin_icon {
 
  font-size: 18px ;

}
.likedin_icon:hover{
  color: #0A66C2 ;
}

.foot_policyLink p:hover{
   color: #f00;;
}


@media screen and (max-width: 899px) {
.footer{
text-align: justify;
margin-left: -15px;
}

.separatorFooter {
  margin-top: -10px;
}
.wrapper_footer {
      display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-left: -15px;
  }
 
  
  .foot_logo {
       width: 100%;
    margin-left: 21px;
    margin-top: -24px;
  }
  
  .foot_about h1 {
    margin-top: -4rem;
   font-size: 2rem;
}
.foot_subs {
    width: 95%;
 margin-top: -13px;
}
.input_field_footer {
width: 111%;
    height: 28px;
    margin-bottom: 20px;
    font-size: 16px;
    border-radius: 1px;
    margin-left: -21px;
}
.foot_subs p {
    font-size: 16px;
    margin-bottom: 16px;
    margin-left: -16px;
}

.foot_subs h5 {
    font-size: 24px;
    margin-bottom: 11px;
    margin-left: -10px;
}
.btnFooter {
width: 119%;
    margin-top: -4px;
    font-size: 21px;
    height: 52px;
    border-radius: 1px;
    margin-left: -19px
}
  /* Menu section */
  .foot_menu {
 display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 0px;
   
  }


  .foot_menu ul {
     display: flex;
    flex-direction: column;
    align-items: center;
  }

  .foot_menu li {
  margin-bottom: 8px;
    display: inline-block;
    width: 100%;
   
  }

  .foot_menu li a:hover {
    color: #f00;
  }

  /* Grid container for all menu sections */
  .foot_menu_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    grid-gap: 20px;
   margin-left: 90px;
  }

  .foot_menu h4 {
  font-size: 18px;
    margin-left: -48px;
  }

  .foot_menu li a {
    font-size: 14px;
    padding-top: 1rem;
    font-weight: 400;
    margin-left: 10px;
  }

  .foot_dowload_appLink h5 {
 margin-top: -27px;
    font-size: 24px;
    margin-left: -20px;
  }

  .app_links {
    display: flex;
    margin-top: -40px;
    margin-left: -20px;
 
  }

  .foot_dowload_appLink img {
  height: 2.2rem;
    margin-top: 10px;
  }
  
      .foot_dowload_appLink{
        margin-left: 67px;
        margin-top: 30px;
      }

      .foot_social{
            margin-left: 120px;
      }
  .css-i4bv87-MuiSvgIcon-root{
    font-size: 2rem !important;
    
  }


}


/* Responsive button and input field width */
@media screen and (max-width: 499px) {
  .foot_form .input_field_footer {
    width: 100%;
  }

  .foot_form .btnFooter {
    width: 100%;
  }
}

/* Responsive font size and margins */
@media screen and (max-width: 499px) {
  .footer .Foot_heading {
    font-size: 20px;
  }

  .foot_subs p {
    font-size: 10px;
    margin-top: 5px;
  }

  .foot_menu_container {
    margin-left: 0;
  }

  .foot_subs p {
    font-size: 12px;
  }

.foot_form .input_field_footer {
  width: 93%;
}

.foot_form .btnFooter {
  width: 100%;
}
  .foot_menu {
    margin-left: 3px;
  }
  .foot_menu ul li,
  .foot_social a {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .sub_footer_wrapper ul li {
    font-size: 10px;
  }
  .foot_policyLink ul {
    justify-content: space-around;
  }
  .foot_policyLink ul {
    margin-left: -31px;
  }
.foot_policyLink_p{
  font-size: 13px;
}
.foot_copyright>* {
 gap: 2px;
  font-size: 11px;
  margin-left: 15px;
}
.app_links {
  gap: 1rem;
}

  .Foot_heading {
    margin-left: 36px;
  }
 
}

@media (max-width: 768px) {
  .container {
      padding: 31px!important;
  }
}
@media screen and (max-width: 899px) {
  .foot_menu h4 {
      font-size: 18px;
      margin-left: -9px;  
  }
}

@media screen and (max-width: 899px) {
  .foot_subs {
      width: 95%;
      margin-top: 7px;
      margin-left: -15px;
  }
}
@media screen and (max-width: 899px) {
  .foot_social {
      margin-left: 40px;
      margin-top: 5px;
  }
}
@media screen and (max-width: 899px) {
  .footer {
    padding-top: 0px!important;
  }
}
