@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  width: 100%;
}
html, body {
  contain: layout;
}
body{
  background-color: #f1f1f1;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: 'Archivo', sans-serif;;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

/* input[type="number"] {
  
  -moz-appearance: textfield;
} */

.greenColor {
  color: green !important; 
}
.redColor { 
  color: red !important;
}

.ck-editor__editable_inline {
  min-height: 200px;
  border: 1px solid #e5e7eb; /* Tailwind gray-300 */
  border-radius: 0.375rem; /* Tailwind rounded-md */
  padding: 1rem;
  background-color: #fff; /* Ensure it's not transparent */
}
/* Add this in your global CSS file if required */
.ck-editor__editable {
  min-height: 200px;
}
